@import "styles/grid.scss";
@import "styles/buttons.scss";
@import "styles/form.scss";
@import "styles/fonts.scss";
@import "styles/variables.scss";

body,
html {
    margin: 0;
    padding: 0;
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    font-size: 62.5%;
    font-weight: 300;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-size: 14px;
}

.textColorWhite {
    color: #FFF;
}

* {
    box-sizing: border-box;
    height: auto;

    &:focus {
        outline: none;
    }
}

*,
*:after,
*:before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4 {
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
}

.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.site-content {
    flex-grow: 1;
    position: relative;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}


/*font-sizes*/

.fs-0 {
    font-size: 0;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px ;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-40 {
    font-size: 40px;
}

.fs-18-resp {
    font-size: 18px;

    @media only screen and (max-width: $md-screen) {
        font-size: 12px;
    }
}

.fs-20 {
    font-size: 20px;
}

.fs-15 {
    font-size: 15px;
}

.fs-19 {
    font-size: 19px;
}

.fs-30 {
    font-size: 30px;
    @media (max-width: 1200px) {
        font-size: 28px;
    }
}

.fs-36 {
    font-size: 36px;
}

.fs-21 {
    font-size: 21px;
}

.fs-20-resp {
    font-size: 20px;

    @media only screen and (max-width: $md-screen) {
        font-size: 14px;
    }
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-28 {
    font-size: 28px;
}

.fs-32 {
    font-size: 32px;
}

.fs-44 {
    font-size: 44px;
}

.fs-44-resp {
    font-size: 44px;

    @media only screen and (max-width: 720px) {
        font-size: 32px;
    }
}

.fs-42-resp {
    font-size: 44px;

    @media only screen and (max-width: 1000px) {
        font-size: 24px;
    }
}

.fs-32 {
    font-size: 32px;
}

.fs-32-resp {
    font-size: 32px;

    @media only screen and (max-width: 720px) {
        font-size: 25px;
    }
}

.fs-32-resp {
    font-size: 32px;

    @media only screen and (max-width: 720px) {
        font-size: 28px;
    }
}

.fs-24-resp {
    font-size: 24px;

    @media only screen and (max-width: 720px) {
        font-size: 16px;
    }
}

.fs-mweb-32-resp {
    font-size: 32px;

    @media only screen and (max-width: 720px) {
        font-size: 14px;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}


/* Text alignment */

.text-align-center {
    text-align: center;
}


/*padding*/

.pd-20{
    padding: 20px;
}

.pd-8{
    padding: 8px;
}

.pd-top-global {
    padding-top: 60px;
}

.pd-top-global-large {
    padding-top: 90px;

    @media only screen and (max-width: 992px) {
        // padding-top: 119px;
        padding-top: 124px;
    }

    // @media only screen and (max-width: 493px) {
    //   padding-top: 176px;
    // }
}

.greyBg {
    background-color: #f4f6fb;
}


/*anchor styles*/

a,
span {
    &.link-black {
        cursor: pointer;
        color: $gray-dark;

        &:hover {
            color: $red-border;
        }
    }

    &.link-white {
        cursor: pointer;
        color: #fff;

        &:hover {
            color: $red-border;
        }
    }

    &.link-green {
        cursor: pointer;
        color: $pri-green;

        &:hover {
            color: $sec-green;
        }
    }

    &.link-red {
        cursor: pointer;
        color: $red-border;

        &:hover {
            color: $sec-red;
        }
    }

    .bottom-nav-icon {
        fill: $sushi-gray-med;
    }

    &.active {
        color: $pri-red;

        .bottom-nav-icon {
            fill: $pri-red;
        }
    }
}

.link-dark-black {
    color: #000;
    cursor: pointer;

    &:hover {
        color: $red-border;
    }
}

a {
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}


/*list*/

ul {
    &.prevent-default {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            list-style: none;
            margin-bottom: 22px;
            width: 100%;
            display: inline-flex;
            align-items: center;

            i {
                margin-right: 10px;
            }
        }
    }
}


/*sushi icon*/

i {
    display: inline-flex !important;
}

.dark-icon {
    color: $pri-green;
}

.gray-icon {
    color: $sushi-gray;
}


/*width*/

.wd-100 {
    width: 100%;
}

.wd-33 {
    width: 33.33%;
}
.wd-30 {
    width: 30%;
}

.wd-45 {
    width: 45% !important;
}

.wd-60 {
    width: 60%;
}

.wd-65 {
    width: 65%;
}

.wd-40 {
    width: 40% !important
}

.wd-60 {
    width: 60%;
}

.wd-70 {
    width: 70%;
}

.wd-25 {
    width: 25%;
}

.wd-75 {
    width: 75%;
}


/*transitions*/

.sidebarTransition {
    transition-delay: 0.1s;
    transition: transform 0.6s ease-in-out;
}


/*text*/

.text-dark {
    color: #000;
}

.text-dark-green {
    color: $dark-green;
}

.lh-1 {
    line-height: 1;
}


/*position*/

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}


/*font-weights*/

.w-200 {
    font-family: "OurLexend-Light", Helvetica, sans-serif;
}

.w-400 {
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
}

.w-600 {
    font-family: "OurLexend-Medium", Helvetica, sans-serif;
}

.w-800 {
    font-family: "OurLexend-SemiBold", Helvetica, sans-serif;
}

.w-1000 {
    font-family: "OurLexend-Bold", Helvetica, sans-serif;
}

.w-1200 {
    font-family: "OurLexend-Bold", Helvetica, sans-serif;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-normal {
    font-weight: normal !important;
}


/*colored-texts*/

.text-dark-blue {
    color: #293142;
}

.text-gray {
    color: $gray-med;
}

.text-gray-400 {
    color: $grey-400;
}

.text-cool-gray-400 {
    color: $cool-grey-400;
}

.text-gray-300 {
    color: $gray-300;
}

.text-cool-gray-600 {
    color: $cool-grey-600;
}

.text-cool-grey-700 {
    color: $cool-grey-700;
}

.text-teal {
    color: $teal;
}

.text-teal-400 {
    color: $teal-400;
}

.text-gray-dark {
    color: $gray-dark;
}

.text-gray-1000 {
    color: $sushi-gray-dark
}

.text-blue {
    color: $blue-500;
}

.text-white {
    color: white;
}

.text-black {
    color: $pri-black;
}

.text-black-active {
    color: $pri-black;

    &.active {
        color: $pri-black;
    }
}

.text-indigo-500 {
    color: $indigo-500;
}

.text-blue-dark {
    color: $blue-dark;
}

.text-green {
    color: $pri-green;
}

.text-purple-500 {
    color: $purple-500;
}

.text-notification-red {
    color: $notification-red;
}

.text-sec-green {
    color: $sec-green;
}

.text-blue {
    color: $sushi-blue-dark;
}

.text-red {
    color: $sushi-red;
}

.text-red-cta {
    color: $red-border;
}

.text-red-700 {
    color: $red-700;
}

.text-red-600 {
    color: $red-600;
}

.text-red-500 {
    color: $red-500;
}

.text-red-400 {
    color: $red-400;
}

.text-pri-red {
    color: $pri-red;
}

.text-orange {
    color: $orange-text;
}

.text-yellow {
    color: $sec-yellow;
}

.text-yellow-600 {
    color: $yellow-600;
}

.text-gray-500 {
    color: $gray-500;
}

.text-gray-800 {
    color: $gray-800;
}

.text-gray-900 {
    color: $gray-900;
}

.text-gray-700 {
    color: $sushi-gray-light;
}

.text-gray-600 {
    color: $sushi-gray-text;
}

.text-grey-600{
    color: $grey-600;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-transform-none {
    text-transform: none;
}

.text-align-right {
    text-align: right;
}


/*line light*/

.line-light {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: $gray-200;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 0.5px solid $gray-200;
}

.line-vertical {
    border-left: 1px solid $gray-med;
    min-height: 22px;
    height: 100%;
}

.line-height-extra {
    line-height: 200%;
}

.pointer {
    cursor: pointer !important;
}

.icon-pointer {
    svg {
        cursor: pointer;
    }
}


/*circle*/

.circle-fill {
    width: 22px;
    height: 22px;
    color: #fff;
    font-size: 12px;
    font-family: "OurLexend-Bold", Helvetica, sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $sushi-gray;
    min-width: 22px;
    min-height: 22px;

    &.active {
        background: $red-border;
        color: #fff;
    }
}


/*disable*/

.disable {
    pointer-events: none !important;
    cursor: auto;
}

.disable-bg {
    background: $gray-100 !important;
    color: $gray-300 !important;

    svg {
        fill: $sushi-gray !important;
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    height: 200px;
}

.word-break {
    word-break: break-all;
}


/*selected properties*/

.triangle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    right: -7px;
    background: #fff;
    top: 20px;
    box-shadow: $box-shadow-spread;
    transform: rotate(45deg);

    &.triangle--down {
        right: -7px;
        bottom: -10px;
        top: auto;
        left: 20px;
    }
}


/*text decoration*/

.captalize {
    text-transform: capitalize;
}


/*uplaoder*/

.image-uploader {
    display: inline-block;
    background: $red-white;
    // border: 1px solid $sushi-green-highlight;
    // font-size: 20px;
    // line-height: 32px;
    padding: 3.5px 5px;
    color: $notification-red;
    width: 100%;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;

    // @extend .mb-5;
    div.bg-image {
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-position: center;
        background-size: cover;
        opacity: 0.2;
        z-index: 0;
    }
}

.input-loader {
    position: absolute;
    top: 0;
    right: 17px;
    margin-top: 17px;

    div {
        border-top-color: $red-border;
        color: $red-border;
    }
}


/*card*/

.card {
    border: 0.6px solid $sushi-gray-border;
    border-radius: 6px;
    margin-bottom: 10px;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    padding: 12px 20px;

    // overflow: hidden;
    &.no-pad {
        padding: 0;
    }

    .card-body,
    .card-footer {
        padding: 12px 20px;
        overflow: hidden;
    }

    &.card-big {
        padding: 15px 20px;

        &:hover {
            box-shadow: $box-shadow-card;
        }
    }

    &.no-transition {
        transition: none;
    }
}

.customCarousel {
    .card {
        width: 260px;
        margin-right: 20px;
    }
}

.card-hover {
    &:hover {
        border: 1px solid $grey-400;
    }
}

.image-card {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 12px 9px;
    background-color: $gray-card-background;
    border-radius: 8px;
    height: 100%;
    border: 1px solid $gray-200;

    @media only screen and (max-width: $sm-screen) {
        padding: 18px 12px;
    }
}

.infinite-scroll-component {
    padding: 20px 0px 0px !important;
}


/* margins */

.section-margin-top {
    margin-top: 0px;

    @media screen and (min-width: $sm-screen) {
        margin-top: 70px;
    }
}


/*No internet wrapper*/

.no-intenet-wrapper {
    position: fixed;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1000;

    span {
        display: inline-block;
        background: $pri-red;
        color: #fff;
        padding: 8px 40px;
        border-radius: 4px 4px 0 0;
        box-shadow: $box-shadow-spread;
    }
}

form .right-icon {
    position: absolute;
    left: 8px;
    color: $gray-med;
    font-size: 12px;
    top: -10px;
    padding: 2px 5px;
    background-color: #fff;
}

.right-icon-input {
    position: absolute;
    right: 15px;
    color: $gray-med;
    font-size: 12px;
    top: 15px;
    padding: 2px 5px;
}

// bg color
.bg-gray-light {
    background-color: $gray-lightest;
}

.bg-gray-wrap {
    background-color: $gray-wrap;
}

.bg-blue {
    background-color: $blue-color-bg;
}

.bg-teal {
    background-color: $teal-white;
}

.bg-orange {
    background-color: $orange-bg;
}

.bg-red {
    background-color: $red-white;
}

.input-btn-group {
    input {
        // padding-right: 55px;
    }

    button {
        span {
            padding: 5px 6px;
        }
    }
}

// panel
.panel-style {
    section {
        padding-top: 0;
    }

    padding: 7px 20px;

    p {
        margin: 10px;
    }

    h5 {
        font-size: 16px !important;
    }
}

// search
.search {
    .right-icon {
        position: absolute;
        right: 10px;
        top: 27%;
    }
}

.bonus-wrapper {
    background-color: $pri-yellow;
    border-radius: 4px;
    box-shadow: $box-shadow-spread;
    padding: 17px 20px;
}

// font weight
.fw-normal {
    font-weight: normal;
}

// carousel
.hp-arrow {
    display: inline-block;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    box-shadow: -4px 5px 12px rgba(60, 64, 69, 0.12);
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: auto;
    border: 4px solid transparent;
    align-self: center;
    cursor: pointer;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

.carousel-custom,
.carousel-custom-p0,
.carousel-custom-sm-pad {
    #react-multi-carousel-item {
        padding: 20px 0 30px 0px;
    }

    &:hover {
        .carousel-arrow-custom {
            visibility: visible;
        }
    }

    .carousel-arrow-custom,
    .carousel-arrow-direction-top {
        position: absolute;
        height: 100%;
        width: 3.4rem;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        visibility: hidden;
        opacity: 1;

        >section::before {
            border-color: $red-600;
        }
    }

    .carousel-arrow-direction-top {
        visibility: visible;
        position: absolute;
        top: -60px;
        right: 0;
        left: auto;
        height: 100%;
        width: 6rem;
        display: inline-block;
    }

    .carousel-arrow-direction-top-small {
        visibility: visible;
        position: absolute;
        top: -40px;
        right: 0;
        left: auto;
        height: 100%;
        width: 6rem;
        display: inline-block;
    }

    .carousel-arrow-custom-visible {
        visibility: visible;

        section {
            box-shadow: -4px 5px 12px rgba(60, 64, 69, 0.12);
        }
    }

    section section+div.carousel-arrow-direction-top {
        right: 50px;
    }
}

.carousel-custom-sm-pad {
    #carousel-items {
        padding: 20px 0 10px 0;
    }
}

.carousel-custom-p0 {
    #carousel-items {
        padding: 0;
    }
}

.word-break {
    word-break: break-word;
}

.white-space-nowrap {
    white-space: nowrap;
}

// strike
.strike {
    text-decoration: line-through;
}

.catalogFilterActive {
    color: $red-600;
    background-color: $red-white;
    border: 1px solid $red-300;
    font-family: "OurLexend-Medium";
    border-radius: 4px;
}

.catalogFilterActiveMweb {
    color: $pri-green;
}

// .pfix-topr {
//   position: absolute;
//   top: 0;
//   right: 11px;
//   background: #ededed;
//   border-radius: 0 4px;
//   padding: 2px 8px;
//   font-size: 13px;
// }
// category header item navigation
.category-header-carousel {
    width: 100%;
    overflow: scroll;
}

.object-contain {
    img {
        object-fit: contain !important;
    }
}

.payment-method-default {
    min-height: 196px;
}

.payment-method-upi {
    min-height: 432px;
}

.payment-method-netbanking {
    min-height: 644px;
}

.payment-method-card {
    min-height: 766px;
}

.mh-100 {
    min-height: 100vh;
}

.toast-top-right {
    color: $info-color !important;
    background: $info-bg !important;

    svg {
        fill: $info-color !important;
    }
}

.info-toast {
    color: $info-color !important;
    background: $info-bg !important;

    svg {
        fill: $info-color !important;
    }
}

.text-info {
    color: $info-color;
}

.mWebToast {
    padding: 7px 15px !important;

    >span {
        font-size: 12px;
    }
}

// scroll
.scrollableList {
    overflow: hidden;
}

.mb-4-resp {
    margin-bottom: 2rem;

    @media only screen and (max-width: $md-screen) {
        margin-bottom: 1rem !important;
    }
}

.mb-5-resp {
    margin-bottom: 3rem;

    @media only screen and (max-width: $md-screen) {
        margin-bottom: 1rem !important;
    }
}

.mb-8{
    margin-bottom: 8px;
}

.mb-32{
    margin-bottom: 32px;
}

.mb-20{
    margin-bottom: 20px;
}

.mb-15{
    margin-bottom: 15px;
}

.mb-4{
    margin-bottom: 4px;
}

.padding-8-16{
    padding: 8px 16px;
}

.mb-16px {
    margin-bottom: 16px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-4px {
    margin-bottom: 4px;
}

.mb-47px {
    margin-bottom: 47px;
}

.mb-80px {
    margin-bottom: 80px;
}
// scroll to top icon
.scroll-to-top {
    position: fixed;
    z-index: 11;
    border-radius: 50%;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: $red-border;
    box-shadow: 0 0 4px $gray-light;
    bottom: 20px;
    right: 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 0 4px $gray-dark;
    }
}

// Modal
.compact-modal {
    section {
        max-width: 540px;
        word-break: break-all;
    }
}

// search
.mweb-search {
    height: 100%;
    z-index: 10;
    // flex-basis: 100px;
}

.carousel-icon {
    width: 25px;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.form-image {
    position: absolute;
    top: 0;
    right: 17px;
    margin-top: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.form-image-error {
    margin-top: -7px;
}

.category-header-item {
    height: 60px;
    width: 186px;
    padding: 6px 12px !important;
    background-color: $white;
    margin: 0 14px 12px 0;
    border-radius: 16px !important;
}

.bannerStripGreen {
    padding: 10px;
    text-align: center;
    background: $light-green;
    border-radius: 8px;
    font-size: 15px;
}

.drop-max-height>div>span {
    max-height: 200px;
    overflow: scroll;
}

.mapMessage {
    background-color: #000;
}

.pac-container {
    border: 0;
    box-shadow: none;
}

.pac-item {
    padding: 8px;
}

.red-smoke-bg {
    background-color: $red-smoke !important;
}

.border-bottom {
    border-bottom: 1px solid $sushi-gray-border;
}

.border-top {
    border-top: 1px solid $sushi-gray-border;
}

.border-left {
    border-left: 1px solid $sushi-gray-border;
}

.border-right {
    border-right: 1px solid $sushi-gray-border;
}

.border {
    border: 1px solid $sushi-gray-border;
}

.vertically-align-middle {
    vertical-align: middle;
}

.vertically-align-top {
    vertical-align: top;
}

.app-download-link {
    background: $app-bg;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    padding: 5px 15px;
    font-size: 11px;
    font-family: "OurLexend-Bold";
    height: 40px;

    @media only screen and (max-width: $sm-screen) {
        width: 100%;
    }
}

.app-share-input {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 0px 15px rgba(228, 233, 235, 0.4);
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: $pri-black;
}

.app-share-btn {
    background: $pri-green;
    padding: 12px 15px;
    text-align: center;
    color: #fff;
    width: 200px;
    cursor: pointer;
    font-family: "OurLexend-SemiBold";
    border-radius: 0 6px 6px 0;

    @media only screen and (max-width: $sm-screen) {
        width: 274px;
    }
}

.tour {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 100;

    &.tour-home-1 {
        .whitebg {
            background: #fff;
            display: flex-inline;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            padding-right: 10px;
        }

        .blackbg {
            position: absolute;
            right: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.8);
            width: 550px;
            height: 500px;
            border-radius: 0% 0 0 100%;
            transform: scale(0);
            animation-name: blackTour;
            animation-fill-mode: forwards;
            animation-duration: 0.2s;
            transform-origin: 100% 0;
        }

        @keyframes blackTour {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
    }

    .dash-line {
        border-left: 1px dashed #fff;
        height: 50px;
        z-index: 1;
        position: relative;
    }

    .tour-dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
    }

    .tour-head {
        color: #fff;
        z-index: 1;
        font-size: 36px;
        margin: 0;
    }

    .tour-desc {
        z-index: 1;
        color: #fff;
        text-align: right;
    }

    .btn {
        z-index: 1;
        color: #fff;
        padding: 10px 15px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid #fff;
        border-radius: 8px;
        margin-top: 20px;
    }
}

.tour-home-2 {
    position: relative;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: -35px;
    margin-bottom: -210px;

    .blurbg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(2.15485px);
    }

    .homeWrap {
        top: 0;
        display: flex;
        width: 100%;
        z-index: 102;
        position: relative;
        background: #fff;
        margin-top: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        border-radius: 6px;
    }

    .blackbg {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: auto;
        text-align: center;
        border-radius: 8px;
        padding-top: 20px;
        animation-name: blackTour;
        animation-fill-mode: forwards;
        animation-duration: 0.2s;
        transform-origin: 50% 0;
        margin-top: 20px;

        @keyframes blackTour {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
    }

    .whitebg {
        background: #fff;
        display: flex-inline;
        position: absolute;
        width: 80%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 101;
        overflow: hidden;
        padding-right: 10px;
        margin: auto;
        left: 0;
        right: 0;
        border-radius: 8px;
        pointer-events: none;
    }

    .dash-line {
        border-left: 1px dashed #fff;
        height: 40px;
        z-index: 1;
        position: relative;
    }

    .tour-dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
        // margin-right: 24px;
    }

    .tour-head {
        color: #fff;
        z-index: 1;
        font-size: 36px;
        margin: 5px 0 0 0;
    }

    .tour-desc {
        z-index: 1;
        color: #fff;
        text-align: center;
    }

    .btn {
        z-index: 1;
        color: #fff;
        padding: 10px 15px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid #fff;
        border-radius: 8px;
        margin-top: 20px;
    }
}

.abs-right-0 {
    position: absolute;
    left: 100%;
}

.ls-2 {
    letter-spacing: 2px;
}

.ls-3 {
    letter-spacing: 3px;
}

.ratingHover1:hover,
.ratingHover2:hover {
    background: $red-700;
    color: #fff;
    border: 1px solid $red-700;

    path {
        fill: white;
    }
}

.ratingHover3:hover {
    background: $yellow-600;
    color: #fff;
    border: 1px solid $yellow-600;

    path {
        fill: white;
    }
}

.ratingHover4:hover,
.ratingHover5:hover {
    background: $dark-green;
    color: #fff;
    border: 1px solid $dark-green;

    path {
        fill: white;
    }
}

.modal-sm {
    max-width: 380px;
    width: 100%;
    margin: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid $sushi-gray;
    text-align: left;
    padding: 8px;
}

.pre-line {
    white-space: pre-line;
}

.mirror-image {
    transform: scaleX(-1);
}

button.solid {
    span {
        background: $red-border !important;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.width-75vw {
    width: 75vw;
}

.mt-05 {
    margin-top: 5px;
}

.mt-40{
    margin-top: 40px;
}

.mt-45{
    margin-top: 45px;
}

.mt-50{
    margin-top: 50px;
}

.mt-24{
    margin-top: 24px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-8{
    margin-left: 8px;
}

.ml-50{
    margin-left: 50px;
}

.ml-40{
    margin-left: 40px;
}

.mt-70{
    margin-top: 70px !important;
}

.mt-14 {
    margin-top: 14px;
}

.ml-06 {
    margin-left : 6px;
}

.wd-84{
    width: 84px !important;
}

.btn-left {
    span {
        display: flex;
        justify-content: space-between;
    }
}

.wd-max-content {
    width: max-content !important;
}

.pd-right-0 {
    padding-right: 0 !important;
}

.mr-right-0 {
    margin-right: 0 !important;
}

.mr-left-0 {
    margin-left: 0 !important;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.bg-white {
    background-color: white;
}

.bg-inherit {
    background-color: inherit;
}

.carousel-status {
    top: 28% !important;
    font-size: 12px !important;
    margin: 0;   
    text-shadow: inherit;
}

.statusHomePage {
    .carousel-status {
        right: 3% !important;
    }
}

.statusCartPage {
    .carousel-status {
        right: -10% !important;
    }

    .carousel-slider {
        width: 87% !important;
        overflow: visible !important;
    }
}

.statusCatalogPage {
    .carousel-status {
        right: -13% !important;
        text-shadow: unset !important;
        color: #293142 !important;
        top: 26% !important;
    }

    .carousel-slider {
        width: 82% !important;
        overflow: visible !important;
    }
}

.displayNone {
    display: none;
}

.height0 {
    height: 0 !important;
}

.carousel-slider {
    li .slide .selected {
        margin-right: 10px;
    }
}

.p-0 {
    padding: 0 !important;
}

#react-autowhatever-1 {
    // padding: 0px 10px 10px 10px;
    // border-left: 1px solid $grey-200;
    // border-bottom:  1px solid $grey-200;
    // border-right: 1px solid $grey-200;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 24px 24px !important;

    ul {
        padding: 0;
        margin: 0;
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
        border-bottom: 1px solid $grey-200;
    }

    li {}
}

#react-autowhatever-1:has(div){
    @media screen and (max-width: $md-screen) {
        box-shadow: none;
        border-radius: 0% !important;
        height: 100%;
    }
}

.pl12 {
    padding-left: 12px;
}

.pl0 {
    padding-left: 0px !important;
}



.terms {
    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    ol>li::marker {
        font-weight: bold;
    }
}

.wd-90 {
    width: 90%;
}

.mr-12px {
    margin-right: 12px;
}

.mb-12px {
    margin-bottom: 12px;
}

.mr-6{
    margin-right: 6px;
}

.mr-16{
    margin-right: 16px; 
}

.fs-14-important {
    font-size: 14px !important;
}

.greyScale-100 {
    filter: grayscale(100%);
}

.height-100 {
    height: 100%;
}

.height-650px {
    height: 650px !important;
}

.height-60px {
    height: 60px;
}

.height-16px {
    height: 16px;
}

.width-100{
    width: 100%;
}

.width-1024{
    width: 1024px;
}

.width-1040{
    width: 1040px;
}

.autoSuggestScrollMain {
    .carousel-root {
        height: 100%;

        .carousel-slider {
            height: 100%;
        }
    }
}

.img32 {
    height: 32px;
    width: 32px;
}

.img14 {
    height: 14px;
    width: 14px;
}

.img16 {
    height: 16px;
    width: 16px;
}

.img20 {
    height: 20px;
    width: 20px;
}

.img30 {
    height: 30px;
    width: 30px;
}

.img200 {
    width: 200px;
}

.img24 {
    height: 24px;
    width: 24px;
}

.px24 {
    padding-left: 24px;
    padding-right: 24px;
}

.pb24 {
    padding-bottom: 24px;
}

.py8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pb8 {
    padding-bottom: 8px;
}

.pt6 {
    padding-top: 6px;
}

.pt8 {
    padding-top: 8px;
}

.mt8 {
    margin-top: 8px;
}
.mx24 {
    margin-left: 24px;
    margin-right: 24px;
}

.py24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.px8 {
    padding-left: 8px;
    padding-right: 8px;
}

.pt24 {
    padding-top: 24px;
}

.pt-50 {
    padding-top: 50px;
}

.modal_body {
    height: 80vh;
}

.modal-footer{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.modal24pxRadius {
    >div:nth-child(2) {
        border-radius: 24px;
        overflow-x: hidden;
        @media screen and (max-width:992px) {
            width: 100vw;
            border-radius: 0%;
        }
    }
}

.img80 {
    height: 80px;
    width: 80px;
}

.img40 {
    height: 40px;
    width: 40px;
}

.img48{
    height: 48px;
    width: 48px;
}

.img64 {
    height: 64px;
    width: 64px;
}

.img160{
    height: 160px;
    width: 160px;
}

.pdLeft16 {
    padding-left: 16px;
}

.pdRight16 {
    padding-right: 16px;
}

.pdLeft8{
    padding-left: 8px;
}

.pdRight8{
    padding-right: 8px;
}

.marginBottomRail{
    margin-bottom: 80px;
}

.mb24 {
    margin-bottom: 24px !important;
}

.mb1 {
    margin-bottom: 1px;
}
.mb16 {
    margin-bottom: 16px;
}

.mb32 {
    margin-bottom: 32px;
}

.mb40 {
    margin-bottom: 40px;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-g1 {
    margin-bottom: 40px;
}

.mb-g2 {
    margin-bottom: 80px;
}

.border-radius-24px{
    border-radius: 24px !important;
}
.px16{
    padding-left: 16px;
    padding-right: 16px;
}
.py16{
    padding-top: 16px;
    padding-bottom: 16px;
}

.mx24{
    margin-left: 24px;
    margin-right: 24px;
}

.img36 {
    height: 36px;
    width: 36px;
}

.btnBdr24{
    span{
        border-radius: 12px;
    }
}
.padding-20 {
    padding: 20px;
}

.mr-24 {
    margin-right: 24px;
}

.mr-20 {
    margin-right: 20px;
}

.p-24 {
    padding: 24px;
}

.width-400 {
    width: 400px;
}

.br-24 {
    border-radius: 24px;
    overflow: hidden;
}

.my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.mx-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.cursor-pointer {
    cursor: pointer;
}


.creditNudge {
    padding-left: 16px;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px; 
    border-radius: 10px;
}

.creditNudgeCross {
    margin-left: 0.25rem !important;
    margin-right: 10px; 
}

.dashed-divider-light {
    border: 1px dashed $grey-100;
    border-radius: 5px;
}

.bg-grey-200{
    background-color : $grey-200;
}

.border-radius-16{
    border-radius: 16px;
}

.bg-indigo-50{
    background-color: $gray-modal-bg;
}

.text-loading-skeleton-md {
    height: 16px;
    border-radius: 16px;
    min-width: 50px;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    color: transparent;
}

@keyframes skeleton-loading {
    0% {
        background-color: $grey-100;
    }

    100% {
        background-color: $grey-200;
    }
}